import * as React from "react"
import { Link } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"

import { useState } from "react";
import "./style.css";
let json_current = require("assets/usmlewiz_treeview.v1.mod.json");


function GeneratePage(){
return(
  <div>List
    <div>{json_current.map((data,index)=>(
      <div>
      <h2>{data["text"]}</h2>
      <div>
        {data["children"].map((xdata,index)=>(
<div>
  <Link to = {"/usmle/uworld/"+xdata["text"]} state={{ fromFeed: true,gg:true,xdata:xdata }}>{xdata["text"] }</Link>
</div>
        ))}
      </div>
      </div>
    ))}</div>
  </div>
)
}


function TopicMCQS(props) {
  
  return (
    <>
    <Layout>
      <Seo title="MCQS" />
        <GeneratePage
         />
    </Layout>
    </>
  )
}

export default TopicMCQS
